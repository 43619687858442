import React, { useEffect, useRef } from 'react';
import { Link } from "gatsby";

import { getFilteredCategories } from "src/helpers";


const Hero = ({categories}) => {
  const subscribeElRef = useRef(null);

  useEffect(() => {
    const subscribeEl = subscribeElRef?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        const element = document.getElementById("header-categories")
        if (!e.isIntersecting) {
          element?.classList.add("show");
          element?.classList.remove("hide");
        } else {
          element?.classList.add("hide");
          element?.classList.remove("show");
        }
      },
      { threshold: [1] }
      );
      
    if (subscribeEl) {
      observer.observe(subscribeEl)
    }
    return (() => {
      observer.unobserve(subscribeEl)
    })

  }, [subscribeElRef]);
  

  return (
    <div className="container hero-articles">
      <h1>Our Articles</h1>
      <span>About babies under one year old and relationships</span>

      <div className="hero-articles__categories" ref={subscribeElRef}>
        {getFilteredCategories(categories)
          .map((categorie, index) => (
            <Link to={`/category/${categorie?.Name}`} key={index} className="link-route" >
              <div className="categories__item" style={{backgroundColor: `#${categorie.Color}`}}>
                <span>{categorie.Name}</span>
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  );
}

export default Hero;
