import React from "react";

import Header from 'src/components/articles-page/Header';
import Hero from "src/components/articles-page/Hero";
import Posts from 'src/components/articles-page/Posts';
import Footer from "src/components/Footer";
import useDataPosts from 'src/hooks/useDataPosts';
import "../styles/index.scss"


const Articles = () => {
  const { categories, posts: {data: posts} } = useDataPosts();

  return (
    <div className="layout-container article-page-wrapper">
      <Header categories={categories} />
      <main>
        <Hero categories={categories} />
        <Posts posts={posts}/>
      </main>
      <Footer />
    </div>
  )
};

export default Articles;
